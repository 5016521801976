<template>
	<div class="data-content">
		<div class="top-box">
			<span class="mr10">{{name}}</span>
			<button class="import-btn" @click="handleImport" v-if="perms.includes('train:question:import') && publishFlag != 1">导入</button>
			<button class="import-btn" @click="handleExport">导出</button>
			<button class="template-btn" @click="handleTemplate" v-if="perms.includes('train:question:import') && publishFlag != 1">模板下载</button>
			<input style="display:none;" ref="uploadExcel" type="file" id="uploadExcel" @change="uploadHandle($event)">
			<div class="top-right-box">
				<button class="add-btn" @click="handleEditData('add',{})" v-if="perms.includes('train:question:add') && publishFlag != 1">新增</button>
				<button class="add-btn" @click="handleBack">返回</button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="questionList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					width="50"
					label="NO.">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="quesContent"
					show-overflow-tooltip
					label="试题内容">
				</el-table-column>
				<el-table-column
					prop="quesType"
					width="100"
					show-overflow-tooltip
					label="类别">
					<template slot-scope="scope">
						<span v-if="scope.row.quesType == 1">单选</span>
						<span v-if="scope.row.quesType == 2">多选</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="answerSelSort"
					width="100"
					show-overflow-tooltip
					label="正确答案">
				</el-table-column>
				<el-table-column
					label="操作"
					width="90">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditData('edit',scope.row)" v-if="perms.includes('train:question:update') && publishFlag != 1">修改</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('train:question:delete') && publishFlag != 1">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getQuestionList="getQuestionList"></edit-data>
		<el-dialog
			:visible.sync="visible"
			:show-close="false"
			:close-on-click-modal="false"
			:close-on-press-escape="false"
			class="role-dialog"
			width="450px">
			<span slot="title" class="dialog-title">
				<span>提示</span>
				<img src="@/assets/images/close.png" alt="" @click="visible = false">
			</span>
			<ul class="error-list">
				<li v-for="(item,i) in errorList" :key="i">{{item}}</li>
			</ul>
		</el-dialog>
	</div>
</template>
<script>
import EditData from './components/edit-question'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			name: '',
			courseId: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			publishFlag: 0,//1发布2撤销
			questionList: [],
			visible: false,
			errorList: [],
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';
		this.getQuestionList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 获取试题数据
		getQuestionList() {
			this.name = this.$route.query.name;
			this.courseId = this.$route.query.courseId;
			let that = this;
			that.$request.post(
				"courseQuestionList",
				true,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					courseId: that.$route.query.courseId
				},
				function (r) {
					if (r.code == "0") {
						that.publishFlag = r.data.list.length > 0 ? r.data.list[0].publishFlag : 0;
						that.questionList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleBack() {
			this.$router.go(-1);
		},
		// 新增/编辑/查看
		handleEditData(type,data) {
			data.courseId = this.courseId;
			this.$refs.editData.init(type,data);
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前试题?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteQuestion',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getQuestionList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getQuestionList();
		},
		handleSizeChange(val) {
			this.getQuestionList();
		},
		handleCurrentChange(val) {
			this.getQuestionList();
		},
		// 导入
		handleImport() {
			let el = document.getElementById('uploadExcel')
            el.click();
		},
		// 上传
		uploadHandle(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('导入文件不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('导入文件不能大于200MB');
					return
				}
				if(file.name.includes('.xlsx')){
					let formdata = new FormData();
					formdata.append('file',file);
					formdata.append('courseId',this.courseId);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'questionImport',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.pageNo = 1;
							that.getQuestionList();
							if(data.data.length > 0) {
								that.visible = true;
								that.errorList = data.data;
							}else {
								that.$message.success('导入成功');
							}
						}
					})
				}else {
					this.$message.error('文件格式不正确');
				}
			}else{
				this.$message.error('请选择上传的文件');
			}
			this.$refs.uploadExcel.value = '';
		},
		// 导出
		handleExport() {
			let that = this
			that.$request.post(
				"questionDownload",
				true,
				{
					courseId: that.courseId
				},
				function (res) {
					if (res.code == '0') {
						const linkNode = document.createElement('a');
						linkNode.style.display = 'none';
						linkNode.href = res.data;
						document.body.appendChild(linkNode);
						linkNode.click();  //模拟在按钮上的一次鼠标单击
						URL.revokeObjectURL(linkNode.href); // 释放URL 对象
						document.body.removeChild(linkNode);
					} else {
						that.$message.error(res.msg)
					}
				}
			)
		},
		// 模板下载
		handleTemplate() {
			const linkNode = document.createElement('a');
			linkNode.style.display = 'none';
			linkNode.href = '/static/template/QUESTION-DATA.xlsx';
			linkNode.download = 'QUESTION-DATA.xlsx';
			document.body.appendChild(linkNode);
			linkNode.click();  //模拟在按钮上的一次鼠标单击
			URL.revokeObjectURL(linkNode.href); // 释放URL 对象
			document.body.removeChild(linkNode);
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.template-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.error-list{
	height: 50vh;
    box-sizing: border-box;
    padding: 20px;
    overflow: auto;
	li{
		list-style: none;
	}
}
</style>

