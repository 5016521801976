<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="500px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="right" ref="dataForm">
			<el-form-item label="试题内容" prop="quesContent">
				<el-input size="small"
					type="textarea"
					:rows="4"
					v-model="dataForm.quesContent"
					placeholder="试题内容"
					maxlength="500"
					:disabled="disable">
				</el-input>
				<el-button :disabled="disable" class="upload-btn" @click="handleImport">上传图片</el-button><br>
				<input style="display:none;" ref="aaa" type="file" id="aaa" @change="uploadHandle1($event)">
				<el-tag v-if="fileName" closable @close="handleRemove">{{fileName}}</el-tag>
			</el-form-item>
			<el-form-item label="排序" prop="sort">
				<el-input size="small" v-model="dataForm.sort" placeholder="排序" maxlength="100" :disabled="disable"></el-input>
			</el-form-item>
			<el-form-item label="试题类别" prop="quesType">
				<el-select size="small" v-model="dataForm.quesType" filterable placeholder="试题类别" :disabled="disable">
					<el-option label="单选" value="1"></el-option>
					<el-option label="多选" value="2"></el-option>
				</el-select>
			</el-form-item>
			<div class="flex">
				<el-form-item label="选项" prop="quesSels" class="flex-1 mr10">
					<el-input size="small" v-model="question" placeholder="选项" maxlength="256" :disabled="disable"></el-input>
				</el-form-item>
				<button type="button" class="btn1" @click="handleAdd">添加</button>
			</div>
			<ul class="tag-ul" v-if="dataForm.quesSels.length > 0">
				<li v-for="(tag,i) in dataForm.quesSels" :key="i">
					<el-tag :closable="(i+1) == dataForm.quesSels.length ? true : false" type="info" @close="handleClose">
						{{i+1 + '.' + tag}}
					</el-tag>
				</li>
			</ul>
			<el-form-item label="答案" prop="answerSelSort">
				<el-input size="small" v-model="dataForm.answerSelSort" placeholder="多选题请使用半角英文逗号区分答案  例如 1,3" :disabled="disable"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer" v-if="!disable">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增试题',
			visible: false,
			disable: false,
			submitUrl: 'addQuestion',
			dataForm: {
				courseId: '',
				quesContent: '',
				quesType: '',
				sort: '',
				answerSelSort: '',
				quesSels: [],
				fileIdList: []
			},
			dataRule: {
				quesContent: [
					{required: true,message: '试题内容不能为空', trigger: "blur"}
				],
				sort: [
					{required: true,message: '排序不能为空', trigger: "blur"}
				],
				quesType: [
					{required: true,message: '请选择试题类别', trigger: "change"}
				],
				quesSels: [
					{required: true,message: '选项不能为空', trigger: "blur"}
				],
				answerSelSort: [
					{required: true,message: '答案不能为空', trigger: "blur"},
					{pattern: /^([1-9]+,?)*$/, message: '请输入1-9用英文半角逗号区分'}
				]
			},
			question: '',
			fileId: '',
			fileName: ''
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				this.dataForm.courseId = data.courseId;
				this.dataForm.fileIdList = [];
				this.question = '';
				this.fileId = '';
				this.fileName = '';
				if(type == 'add') {
					this.title = '新增试题';
					delete this.dataForm.id;
					this.submitUrl = 'addQuestion';
					this.disable = false;
				}
				else if(type == 'edit') {
					console.log(data)
					this.title = '修改试题';
					this.submitUrl = 'updateQuestion';
					this.disable = false;
					this.dataForm.id = data.id;
					this.dataForm.quesContent = data.quesContent;
					this.dataForm.sort = data.sort+'';
					this.dataForm.quesType = data.quesType+ '';
					this.dataForm.answerSelSort = data.answerSelSort;
					let quesSelsObj = JSON.stringify(data.quesSels);
					this.dataForm.quesSels = JSON.parse(quesSelsObj);
					if(data.sysAttachFileList.length > 0) {
						this.fileId = data.sysAttachFileList[0].id;
						this.fileName = data.sysAttachFileList[0].fileName;
					}
				}
				else {
					this.title = '查看试题';
					this.submitUrl = '';
					this.disable = true;
					this.dataForm.id = data.id;
					this.dataForm.quesContent = data.quesContent;
					this.dataForm.sort = data.sort+'';
					this.dataForm.quesType = data.quesType+ '';
					this.dataForm.answerSelSort = data.answerSelSort;
					let quesSelsObj = JSON.stringify(data.quesSels);
					this.dataForm.quesSels = JSON.parse(quesSelsObj);
					if(data.sysAttachFileList.length > 0) {
						this.fileId = data.sysAttachFileList[0].id;
						this.fileName = data.sysAttachFileList[0].fileName;
					}
				}
			})
		},
		// 添加选项
		handleAdd() {
			if(this.question == '' || this.disable) {
				return
			}
			this.dataForm.quesSels.push(this.question);
			this.question = '';
			this.$refs.dataForm.validateField("quesSels");
		},
		// 移除选项
		handleClose() {
			if(this.disable){
				return
			}
			this.dataForm.quesSels.splice(this.dataForm.quesSels.length-1,1);
		},
		// 导入
		handleImport() {
			if(this.disable) {
				return
			}
			if(this.fileName) {
				this.$message.error('请删除已有图片再进行上传');
				return
			}
			let el = document.getElementById('aaa');
            el.click();
		},
		uploadHandle1(e) {
			let file = e.target.files[0];
            if(file){
				if(window.sessionStorage.getItem('userInfo').superAdminFlag == '1' && file.size/1024/1024/1024 > 1) {
					this.$message.error('上传图片不能大于1GB');
					return
				}
				// 2022-07-31 应客户要求改为200M
				if(window.sessionStorage.getItem('userInfo').superAdminFlag != '1' && file.size/1024/1024 > 200) {
					this.$message.error('上传图片不能大于200MB');
					return
				}
				if(['image/png','image/jpg','image/jpeg'].includes(file.type)){
					let formdata = new FormData();
					formdata.append('file',file);
					let that = this;
					that.$request.initUploadExcel(
						formdata,
						'sysFileUpload',
						true
					)
					.then((data) => {
						if(data.code == 0) {
							that.fileName = data.data.fileName;
							that.fileId = data.data.id;
						}
					})
				}else {
					this.$message.error('上传图片仅支持.png .jpg格式');
				}
			}else{
				this.$message.error('请选择上传的图片');
			}
			this.$refs.aaa.value = '';
		},
		// 删除图片
		handleRemove() {
			if(this.disable) {
				return
			}
			this.fileName = '';
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.fileName && this.dataForm.fileIdList.length ==0 ? this.dataForm.fileIdList.push(this.fileId) : '';
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let submitForm = this.dataForm;
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						submitForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getQuestionList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});

		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 20px;
	.el-select,
	.el-cascader{
		width: 100%;
	}
	.select /deep/ .el-input__inner{
		height: 32px;
		line-height: 32px;
	}
	.el-cascader{
		line-height: 32px;
		/deep/ .el-input__inner{
			height: 32px;
		}
	}
}
.submit-btn{
	display: block;
	width: 60%;
	margin: 0 auto;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
	line-height: 24px !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 0px 20px 40px !important;
}
/deep/ .el-upload {
	width: 100%;

	.el-upload-dragger {
		width: 100%;
	}
}
/deep/ .el-upload-dragger {
	display: flex;
	align-items: center;
	justify-content: space-around;
}
.flex{
	display: flex;
}
.flex-1{
	flex: 1;
}
.btn1{
	width: 60px;
	height: 32px;
	text-align: center;
	color: #000;
	background-color: #fff;
	outline: none;
	border: 1px solid #ddd;
	border-radius: 5px;
	cursor: pointer;
	margin-top: 27px;
}
.tag-ul{
	li{
		list-style: none;
		margin-bottom: 5px;
	}
}
</style>
